import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "vueApp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatabaseComponent = _resolveComponent("DatabaseComponent")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_DatabaseComponent)
      ]),
      _: 1
    }))
  ]))
}